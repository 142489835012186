@import "../global/mixins";
@import "../global/animations";

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: var(--black);
  // padding: getRem(18.5) getRem(19) getRem(12);
  padding: 0;
  position: relative;
  background: var(--white);
  box-shadow: var(--shadow);
  border-radius: 0 0 6px 6px;

  @include breakpoint(mobile-tablet) {
    box-shadow: 0 0 getRem(16) 0 rgba(0, 0, 0, 0.2);
  }

  // border-top: 3px solid transparent;
  // margin: 1rem;
  h3,
  p {
    color: var(--black);
  }

  h3 {
    font-size: getRem(20);
    @include lineHeightRatio(20, 24);
    margin: 0 0 getRem(18);
    padding: 0;
    // clamp
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    -webkit-box-orient: vertical;

    a {
      color: var(--text-color) !important;
    }
  }

  p {
    margin: 0;
  }

  a {
    margin: 0;

    // TODO: determine if we want a hover transition or not.  Pros and cons to each.
    // h3 {
    //     transition: color 0.33s md-ease(ease-in-out);
    // }
    &:hover h3 {
      color: var(--red);
    }
  }
}

.gridCard {
  h3 {
    margin-top: getRem(8);
  }

  .summary {
    margin-top: getRem(12);
  }
}

.body {
  @include breakpoint(tablet-desktop) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //height: getRem(275);
  }

  @include breakpoint(mobile) {
    padding: 0 !important;
  }
}

.cardBottom {
  padding: getRem(8) getRem(16);

  @include breakpoint(mobile) {
    margin: 0 !important;
    padding: getRem(8) getRem(16) !important;
  }

  .super {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;

    .superText {
      flex-wrap: nowrap;
      // margin-top: getRem(10);

      @include breakpoint(mobile) {
        flex-wrap: wrap;
      }
    }

    img,
    span,
    svg {
      display: inline-block;
      vertical-align: middle;
    }

    .countryContainer {
      display: inline-flex;
      align-items: center;
      // overflow: hidden;
      // text-overflow: ellipsis;

      .metaHover {
        svg {
          path {
            fill: #2D2D2D;
            stroke: transparent !important;
          }
        }
      }
    }

    p {
      display: flex;
      align-items: center;
      @include font(14, 24);
      color: #6F6F6F;
      letter-spacing: normal;
      text-transform: none;
      white-space: nowrap;
      margin-right: getRem(8);

      span {
        @include font(14, 24);
        letter-spacing: normal;
        text-transform: none;
      }

      &.country {
        // flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;

        svg {
          display: inline-block;
          margin-top: getRem(-2);
        }
      }

      &.category {
        max-width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    img,
    svg {
      margin-right: getRem(4);
    }

    svg path {
      fill: #6F6F6F;
    }
  }

  .date {
    @include font(14, 24);
  }

  .meta {
    margin-top: getRem(12);

    span {
      span {
        @include font(14, 24);
      }
    }
  }

  .stamp {
    margin-left: 0;
    max-height: 60%;

    .stamps {
      clear: both;
    }
  }
}

.categoryContainer,
.countryContainer {
  max-width: 100%;
  min-width: getRem(60);
  display: inline-flex;
  position: relative;

  &:hover {
    .metaHover {
      display: inline-block;
    }
  }

  .metaHover {
    width: 100%;
    min-width: getRem(124);
    background: #FFFFFF;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    color: black;
    display: none;
    padding: getRem(16);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;

    .hoverLabel {
      color: var(--black);
      @include font(14, 18);
      margin: 0;

      span {
        color: var(--black);
        @include font(14, 18);
        text-transform: uppercase;
      }

      svg {
        margin-right: getRem(4);
      }
    }

    ul {
      list-style: none;
      margin: getRem(10) 0 0;
      padding: 0;

      li {
        color: var(--black);
        @include font(14, 18);
        margin-top: getRem(4) !important;
        // white-space: nowrap;

        a {
          color: #006BAE;
          font-weight: normal;
          text-transform: none;
          white-space: break-spaces;
        }

        p {
          margin: 0;
          color: var(--black);
          @include font(14, 18);
        }
      }
    }

    svg path {
      fill: #2D2D2D;
    }
  }
}

.categoryContainer {
  @include breakpoint(mobile) {
    width: 100%;
  }
}

.countryContainer {
  .metaHover {
    width: auto;
    min-width: 100%;

    svg {
      path {
        stroke: transparent !important;
      }
    }

    li {
      white-space: nowrap;
    }
  }
}

.titleOnlyCard {
  max-width: getRem(400);
  padding: getRem(18);

  @include breakpoint(mobile) {
    max-width: none;
  }

  h3 {
    margin: 0;
    padding-bottom: getRem(18);
    border-bottom: 1px solid #383838;
  }

  .cardBottom {
    padding-top: getRem(8);
  }

  .super {
    display: block;
    margin-bottom: 0;
    padding-bottom: 0;

    .superText {
      flex-wrap: nowrap;
      // margin-top: getRem(10);

      @include breakpoint(mobile) {
        flex-wrap: wrap;
      }
    }

    img,
    span,
    svg {
      display: inline-block;
      vertical-align: middle;
    }

    p {
      display: flex;
      align-items: center;
      @include font(14, 24);
      letter-spacing: normal;
      text-transform: none;
      white-space: nowrap;

      span {
        @include font(14, 24);
        letter-spacing: normal;
        text-transform: none;
      }

      &.country {
        // flex-shrink: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
      }

      &.category {
        max-width: 100%;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: getRem(8);
      }
    }

    img,
    svg {
      margin-right: getRem(4);
    }
  }

  .categoryContainer,
  .countryContainer {
    max-width: calc(100% - 50px);
    display: inline-flex;
    position: relative;

    &:hover {
      .metaHover {
        display: inline-block;
      }
    }

    .metaHover {
      width: 100%;
      min-width: getRem(124);
      background: #FFFFFF;
      color: black;
      display: none;
      padding: getRem(16);
      position: absolute;
      top: 0;
      left: getRem(8);
      z-index: 100;

      .hoverLabel {
        color: #292929;
        @include font(14, 18);
        margin: 0;

        span {
          @include font(14, 18);
          text-transform: uppercase;
        }
      }

      ul {
        list-style: none;
        margin: getRem(10) 0 0;
        padding: 0;

        li {
          color: #006BAE;
          @include font(14, 18);
          // white-space: nowrap;
        }
      }
    }
  }

  .date {
    @include font(14, 24);
  }

  .meta {
    margin-top: getRem(10);

    span {
      span {
        @include font(14, 24);
      }
    }
  }

  .stamp {
    margin-left: 0;
    max-height: 60%;

    .stamps {
      clear: both;
    }
  }
}

.largeCard {
  grid-column: 1 / span 3;
  padding: getRem(25.5) getRem(28.5) getRem(20.5);

  @include breakpoint(tablet) {
    grid-column: 1 / span 2;
  }

  @include breakpoint(mobile) {
    grid-column: 1 / span 1;
  }
}

.largeTitleBlock {
  display: grid;
  grid-template-columns: auto getRem(200);
  align-items: flex-end;
  margin: getRem(12) 0 getRem(20);

  h3 {
    margin: 0;
    padding: 0;
  }

  div {
    padding-bottom: 0.3rem;
  }
}

.cardLink {

  // display: block;
  &:hover {
    color: var(--red);
  }
}

.dark {
  --black: #fff;
  --white: #{rgba(#111, 0.85)};
  --text-light: $fff;
  border: getRem(1) solid #6f6f6f;
  // TODO: Remove - this doesn't work
  // background-blend-mode: multiply;
  // background-color: transparent;
  // background-image: linear-gradient(to bottom, #{rgba(#2B2B2B, .85)}, #{rgba(#2B2B2B, .85)});
  // TODO: Run past Emma (Yes!)
  backdrop-filter: saturate(180%) blur(20px);
  -webkit-backdrop-filter: saturate(180%) blur(20px);

  a:hover,
  a:hover h3 {
    color: var(--yellow);
  }
}

.unread {
  &::after {
    content: "";
    width: 100%;
    height: getRem(6);
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--red);
  }
}

// Kinda hacky, but makes sure dark cards line up regardless of read status

.dark.unread {
  position: relative;

  &::after {
    content: "";
    width: 100%;
    height: getRem(2);
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--red);
  }
}

.imageContainer,
.videoContainer {
  position: relative;
  // background-color: var(--image-placeholder-color);
  // background-image: var(--image-placeholder);
  background-position: center;
  color: white;
  background-repeat: no-repeat;
  margin-bottom: getRem(18);
}

.imageContainer {
  max-width: 100%;
  height: auto;
  // min-height: 100%;
  // // aspect-ratio: 1;
  display: inherit;
  // display: none;
  align-self: flex-start;

  @include breakpoint(mobile) {
    aspect-ratio: 4/3;
  }

  &.iconContainer {
    background: var(--yellow);
    display: flex;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    padding: getRem(32) 0;

    svg {
      width: 65%;
      height: auto;

      @include breakpoint(mobile) {
        width: 50%;
      }
    }
  }

  &.fgdImage {
    aspect-ratio: 1.75;
    display: flex;
    padding: getRem(16);

    img {
      width: auto;
      height: 100%;
      margin: auto;
    }
  }

  &.fileIcon {
    svg {
      width: 40%;

      path {
        fill: var(--white);
      }
    }
  }

  span {
    width: 100%;
    height: 100% !important;

    img {
      height: 100% !important;
      max-height: none !important;
    }
  }
}

// FGD background-colors
.card {
  :global {

    .webcast,
    .mcflurry,
    .mccafe,
    .mcdelivery,
    .webcast-recordings {
      background-color: var(--yellow);
    }

    .masterbrand,
    .happy-meal,
    .mymcdonalds {
      background-color: var(--red);
    }

    .premium {
      background-color: var(--black);
    }
  }
}

.textContainer {
  padding: getRem(18);
}

.summary {

  span,
  p {
    @include font(16, 26);
    display: block;
    margin: getRem(8) 0 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    strong {
      font-weight: 400;
    }
  }

  span {
    p:not(:first-child) {
      display: none;
    }
  }
}

.super {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 0 0 getRem(15);

  // padding-right: getRem(45);
  .superText {
    display: flex;

    @include breakpoint(mobile) {
      flex-wrap: wrap;
      grid-gap: getRem(8);
    }
  }

  span {
    font-size: getRem(14);
    @include lineHeightRatio(14, 14);
    letter-spacing: getRem(1.75);
    text-transform: uppercase;
  }

  span:not(:last-of-type) {

    // margin-right: 1em;
    // padding-right: 1em;
    &::after {
      margin: 0 0.5em;
      content: "•";
    }
  }
}

.stamp {
  margin-left: auto;
  flex-shrink: 0;
}

.meta {
  font-size: getRem(16.8);
  @include lineHeightRatio(16.8, 28.8);
  color: var(--text-light);
  letter-spacing: 0;
  display: flex;
  flex-wrap: wrap;
  grid-column-gap: getRem(15);
  align-items: center;
  margin: getRem(12) 0 0;

  .bottomMeta {
    width: 100%;
    display: flex;
    margin-top: getRem(32);
    grid-column-gap: getRem(16);

    @include breakpoint(mobile) {
      grid-column-gap: getRem(8);
    }
  }

  p {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .date,
  .category,
  .country {
    @include font(14, 24);
    margin: 0;
    text-transform: none;

    img,
    svg {
      margin-right: getRem(4);
    }

    svg {
      display: inline-block;
      vertical-align: middle;

      path {
        fill: var(--white);
      }
    }
  }

  .country {
    // flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;

    svg {
      margin-top: getRem(-2);
    }
  }

  .category {
    // margin-left: getRem(8);
  }

  .countryContainer {
    display: inline-flex;
    align-items: center;
  }

  .view {
    span {
      @include font(14, 18);
      margin-left: getRem(6);
    }

    svg {

      path,
      circle {
        stroke: var(--white);
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      margin-left: 0.25rem;
    }
  }

  button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;

    span {
      width: 100%;
      height: 100%;
      background-color: transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }

    svg {
      position: relative;
      z-index: -1;
    }
  }

  &.ArticleMeta {}
}

.bookmark button {
  color: transparent;
  margin-top: getRem(3);
  transition: filter 0.33s md-ease(ease-in-out);
  background-color: transparent;
  border: none;
  filter: drop-shadow(0px 1px 6px rgba(#ffbc0d, 0));

  &:hover {
    filter: drop-shadow(0px 1px 6px rgba(#ffbc0d, 1));
    // color: rgba(#ffbc0d, 0.5);
    // opacity: 0.25;
  }

  // @include headShake;
  // &:not(:focus-visible) {
  //     outline: none !important; // TODO: remove this for a11y
  // }
}

.bookmarked button {
  color: var(--yellow);
  // @include bounce;
  // &:hover {
  //     color: rgba(#ffbc0d, 0.75);
  //     color: transparent;
  //     opacity: 0.75;
  // }
}

@include breakpoint(mobile) {
  .summary {
    display: none;
  }
}

.cardLong {
  grid-column: 1 / span 3;
  height: auto;
  display: grid;
  // grid-template-columns: minmax(calc(42% - 16px), calc(42% - 16px)) minmax(calc(58% - 16px), calc(58% - 16px));
  grid-template-columns: 65% 35%;
  // gap: 0 1rem;

  @media screen and (min-width: 1300px) {
    // padding-bottom: 2rem;
    // padding-left: 2rem;
    // padding-right: 2rem;
    // gap: 0 2rem;

    h3 {
      font-size: getRem(40);
      @include lineHeightRatio(40, 48);
      margin-top: -0.2em;
    }
  }

  .body {
    // width: 541px;
    // width: 100%;
    height: auto;
    padding: getRem(20) getRem(24);
  }

  .super {
    grid-column: 1 / span 2;
    // display: none;
  }

  .cardBottom {
    padding: getRem(8) 0;
  }

  .textContainer {
    padding: 0 0 getRem(18);
  }

  .imageContainer {
    height: 100%;
  }
}

.objectType {
  @include font(14, 14);
  margin: 0 0 getRem(10) !important;

  span,
  svg {
    display: inline-block;
    vertical-align: middle;
  }

  svg {
    max-width: getRem(20);
    max-height: getRem(20);
    margin-right: getRem(4);
  }
}

.followOptionsContainer {
  ul {
    width: 100%;
    display: inline-block !important;
  }

  li {
    &>div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}